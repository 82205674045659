/**
 * Fix for select2 required tooltip:
 * https://github.com/select2/select2/issues/128
 */

.form-control.userForm-country-control.select2-offscreen {
    top: 48px !important;
    width: 100% !important;
}

.form-control.userForm-state-control.select2-offscreen {
    top: 62px !important;
    width: 100% !important;
}