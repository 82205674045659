/**
 * Glyphicon helpers
 */

.glyphicon {

    &.glyphicon-scale-sm {
        font-size: 0.8em;
    }

    &.glyphicon-scale-md {
        // regular
    }

    &.glyphicon-scale-lg {
        font-size: 5em;
    }

    &.glyphicon-scale-xl {
        font-size: 10em;
    }

}