/**
 * Bootstrap < 3.2.0 fix for missing visible-x-inline classes.
 * If we upgrade to 3.2.x or greater, remove this fix.
 */

.visible-xs-inline,
.visible-sm-inline,
.visible-md-inline,
.visible-lg-inline {
  display: none !important;
}
.visible-xs-inline-block,
.visible-sm-inline-block,
.visible-md-inline-block,
.visible-lg-inline-block {
  display: none !important;
}

@media (max-width: 767px) {
    .visible-xs-inline {
        display: inline !important;
    }
    .visible-xs-inline-block {
        display: inline-block !important;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .visible-sm-inline {
        display: inline !important;
    }
    .visible-sm-inline-block {
        display: inline-block !important;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .visible-md-inline {
        display: inline !important;
    }
    .visible-md-inline-block {
        display: inline-block !important;
    }
}
@media (min-width: 1200px) {
    .visible-lg-inline {
        display: inline !important;
    }
    .visible-lg-inline-block {
        display: inline-block !important;
    }
}