/* General */
a[data-id='changeHistory'] > span.badge, .badge-effective {
    background-color: rgb(214, 228, 255);
    color: rgb(33, 33, 33);
    font-weight: 500;
    padding: 0.3rem 0.75rem;
}

/* Effectivity Form */
.effectiveDateForm,
#effectivityForm,
#basicEffectivityForm,
#position-assignment-duration-form-top {
    background-color: #F6F6F6;
    border-radius: 8px;
    padding: 14px 0;
    margin: 20px 0 0 0;
}

.effectiveDateForm h4,
#effectivityForm h4,
#basicEffectivityForm h4{
    font-weight: 700;
    line-height: 21.98px;
}

#effectivityFormConfirmation #effectivityFormConfirmationEffectivityFields {
    margin-top: 14px;
}

#effectivityFormConfirmation .modal-footer {
    border-top: 0;
}

#effectivityForm .form-control.select2-offscreen,
#position-assignment-duration-form-top .form-control.select2-offscreen,
.make-primary-position-confirmation-wrapper .form-control.select2-offscreen {
    left: 22px !important;
    top: 58px !important;
}

#effectivityForm .effectiveDating-autofill-label {
    vertical-align: middle;
    font-weight: 400
}

.effectivity-notice-section {
    margin-top: 24px;
    margin-bottom: 4px;
}

@media (max-width:767px) {
    .effectiveDating-autofill-wrapper > input[type=checkbox].effectiveDating-autofill {
        width: 13px;
        height: 13px;
        margin-right: 0;
        margin-left: 13px
    }
}

.elmo-busy-invisible {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2147483647;
  cursor: progress;
}
.elmo-busy {
  width: 100%;
  height: 100%;
  cursor: progress;
  overflow: hidden;
}
.elmo-busy .wrapper {
  background: rgba(255, 255, 255, 0.5);
  text-align: center;
  z-index: 99;
}
.elmo-busy .wrapper .align {
  position: relative;
  width: 100%;
  height: 100%;
}
/**
 * Note: Spinner can be used outside of elmo-busy, so it's important this is declared globally.
 */
.elmo-busy-spinner {
  width: 40px;
  height: 40px;
  background: url('/assets/images/elmo-busy/loading.gif');
  background: url('/assets/images/elmo-busy/loading.svg'), linear-gradient(transparent, transparent);
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
}

/**
 * ELMO TMS Framework
 *
 * Status Colours
 */
/**
 * Document Statuses
 *
 * Example
 * <span class="elmo-document-state state-not-yet-started">Not Yet Started</span>
 */
.elmo-document-state.state-not-yet-started {
  color: #CC2200;
}
.elmo-document-state.state-recompletion {
  color: #CC2200;
}
.elmo-document-state.state-expired {
  color: #CC2200;
}
.elmo-document-state.state-pending-approval {
  color: #FF7E00;
}
.elmo-document-state.state-rejected {
  color: #CC2200;
}
.elmo-document-state.state-completed {
  color: #6AA84F;
}
.elmo-document-state.state-in-progress {
  color: #FF7E00;
}

/**
 * ELMO TMS Framework
 *
 * Status Colours
 */

/**
 * Bootstrap < 3.2.0 fix for missing visible-x-inline classes.
 * If we upgrade to 3.2.x or greater, remove this fix.
 */
.visible-xs-inline,
.visible-sm-inline,
.visible-md-inline,
.visible-lg-inline {
  display: none !important;
}
.visible-xs-inline-block,
.visible-sm-inline-block,
.visible-md-inline-block,
.visible-lg-inline-block {
  display: none !important;
}
@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important;
  }
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important;
  }
  .visible-md-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important;
  }
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}
/**
 * Fix for select2 required tooltip:
 * https://github.com/select2/select2/issues/128
 */
.form-control.userForm-country-control.select2-offscreen {
  top: 48px !important;
  width: 100% !important;
}
.form-control.userForm-state-control.select2-offscreen {
  top: 62px !important;
  width: 100% !important;
}
/**
 * Glyphicon helpers
 */
.glyphicon.glyphicon-scale-sm {
  font-size: 0.8em;
}
.glyphicon.glyphicon-scale-lg {
  font-size: 5em;
}
.glyphicon.glyphicon-scale-xl {
  font-size: 10em;
}
/**
 * Table styles for TMS
 * Relocated from default.css
 */
#elmo-table,
.elmo-table {
  border: none;
}
#elmo-table th,
.elmo-table th {
  border: none;
}
#elmo-table tbody tr:first-child td,
.elmo-table tbody tr:first-child td {
  border-top: none;
}
#elmo-table tfoot tr td,
.elmo-table tfoot tr td {
  border: none;
}
#elmo-table td,
.elmo-table td,
#elmo-table th,
.elmo-table th {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #ddd;
  border-left: none;
  border-right: none;
}
#elmo-table td.no-top-border,
.elmo-table td.no-top-border,
#elmo-table th.no-top-border,
.elmo-table th.no-top-border {
  border-top: none;
}
#elmo-table td.name-column,
.elmo-table td.name-column,
#elmo-table th.name-column,
.elmo-table th.name-column {
  width: 30%;
  word-break: break-all;
}
#elmo-table td p,
.elmo-table td p {
  margin: 0 !important;
}
#elmo-table tr.empty td,
.elmo-table tr.empty td {
  padding: 10px;
  text-align: center;
}
#elmo-table tr.empty td .glyphicon-scale-xl,
.elmo-table tr.empty td .glyphicon-scale-xl {
  opacity: 0.2;
  padding: 10px 20px 20px 20px;
}
#elmo-table tr.deleted,
.elmo-table tr.deleted {
  background-color: rgba(204, 204, 204, 0.5);
}
#elmo-table tr.deleted td,
.elmo-table tr.deleted td {
  opacity: 0.5;
}
#elmo-table tr.deleted td.actions,
.elmo-table tr.deleted td.actions {
  opacity: 1;
}
#elmo-table tr.deleted:hover td,
.elmo-table tr.deleted:hover td {
  opacity: 1;
}
#elmo-table div.div-table-cell,
.elmo-table div.div-table-cell {
  display: table-cell;
  vertical-align: middle;
  padding: 3px;
}
#elmo-table div.div-table-cell.main-cell,
.elmo-table div.div-table-cell.main-cell {
  width: 100%;
}
.user-config-container .config-table td.active {
  background-color: #999999 !important;
}
.user-config-container .config-header-16 {
  font-size: 16px !important;
}
.user-config-container .config-select-table {
  float: left;
  padding-left: 0px;
  width: 150px;
  background-color: white;
}
.user-config-container .config-repeated {
  float: right;
  padding-left: 0px;
  padding-right: 0px;
}
.user-config-container .config-left {
  float: left;
}
.user-config-container .config-right {
  float: right;
  padding-right: 20% !important;
}
.user-config-container .config-disabled {
  background-color: #DDD;
}
.user-config-container .config-selected {
  color: #888;
}
.user-config-container .config-label {
  float: left;
  padding-right: 0px;
}
.table-scrollable {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}
/**

# ELMO Multi-tier dropdown

Allows a bootstrap drop-down to have sub-dropdown items.
http://bootsnipp.com/snippets/featured/multi-level-dropdown-menu-bs3

# Usage
```html
    <div class="dropdown">
        <ul class="dropdown-menu multi-level" role="menu" aria-labelledby="dropdownMenu">
            <li><a href="#">Some action</a></li>
            <li><a href="#">Some other action</a></li>
            <li class="dropdown-submenu">
                <a tabindex="-1" href="#">Hover me for more options</a>
                <ul class="dropdown-menu">
                    <li><a tabindex="-1" href="#">Second level</a></li>
                    <li class="dropdown-submenu">
                        <a href="#">Even More..</a>
                        <ul class="dropdown-menu">
                            <li><a href="#">3rd level</a></li>
                            <li><a href="#">3rd level</a></li>
                        </ul>
                    </li>
                    <li><a href="#">Second level</a></li>
                    <li><a href="#">Second level</a></li>
                </ul>
            </li>
            <li><a href="#">First level</a></li>
        </ul>
    </div>
```
*/
.dropdown-menu li {
  text-align: left;
}
.dropdown-menu li.dropdown-submenu {
  position: relative;
}
.dropdown-menu li.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
}
.dropdown-menu li.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.dropdown-menu li.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-right: -10px;
}
.dropdown-menu li.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}
.dropdown-menu li.dropdown-submenu.pull-left {
  float: none;
}
.dropdown-menu li.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}
/**
 * SuperInfo styles
 * @see app/Resources/views/Elements/superInfo.html.twig
 */
span[data-trigger="sinfo"] {
  opacity: 1;
  color: #000;
  cursor: pointer;
  pointer-events: all;
}
.popover.super-info {
  z-index: 2000;
  min-height: 4em;
  text-align: center;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex !important;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
  min-width: 100px;
  max-width: none;
}
.popover.super-info .super-info-extended {
  margin: 0px -14px -9px -14px;
  padding: 5px 0;
  text-align: center;
}
.modal.modal-sinfo {
  z-index: 2500;
}
/**
 * # ELMO Pagination Small
 * Blantant rip-off of Atlassian Jira pagination styles
 *
 */
/**
 * Bootstrap Pagination extension
 *
 * ## HTML
 * Extends the default Bootstrap pagination class @see http://getbootstrap.com/components/#pagination
 * <ul class="pagination elmo-pagination-small"> .. </ul>
 */
.pagination.elmo-pagination-small {
  margin: 5px;
  border-radius: 0;
}
.pagination.elmo-pagination-small > li > a,
.pagination.elmo-pagination-small > li > span {
  border: none;
  padding: 4px;
}
.pagination.elmo-pagination-small > li > a:hover,
.pagination.elmo-pagination-small > li > span:hover,
.pagination.elmo-pagination-small > li > a:focus,
.pagination.elmo-pagination-small > li > span:focus {
  background-color: transparent;
  text-decoration: underline;
}
.pagination.elmo-pagination-small > .active > a,
.pagination.elmo-pagination-small > .active > span,
.pagination.elmo-pagination-small > .active > a:hover,
.pagination.elmo-pagination-small > .active > span:hover,
.pagination.elmo-pagination-small > .active > a:focus,
.pagination.elmo-pagination-small > .active > span:focus {
  font-weight: bold;
  color: #000;
  background-color: transparent;
}
.pagination.elmo-pagination-small > .next > a > span,
.pagination.elmo-pagination-small > .prev > a > span {
  width: 0;
  height: 0;
  display: block;
  overflow: hidden;
  margin-top: 0.2em;
  border-top: 0.5em solid transparent;
  border-bottom: 0.5em solid transparent;
}
.pagination.elmo-pagination-small > .next > a span {
  border-left: 0.5em solid #666;
}
.pagination.elmo-pagination-small > .next > a:hover span,
.pagination.elmo-pagination-small > .next > a:focus span {
  border-left-color: #000;
}
.pagination.elmo-pagination-small > .prev > a span {
  border-right: 0.5em solid #666;
}
.pagination.elmo-pagination-small > .prev > a:hover span,
.pagination.elmo-pagination-small > .prev > a:focus span {
  border-right-color: #000;
}
/**
 * Pagination counter styles
 *
 * ## HTML (custom)
 * <div class="pagination-count elmo-pagination-small"><span>1-10 of <a href="#">50</a></span></div>
 */
.pagination-count.elmo-pagination-small {
  margin: 5px;
  padding: 4px;
}
/**
 * Styles for the epms-table-index template
 * @see app/Resources/views/Page/Admin/table_view.html.twig
 */
table[data-interface="epms-table-index"] tr[data-url] {
  cursor: pointer;
}
table[data-interface="epms-table-index"] tr .actions {
  white-space: nowrap;
}
table[data-interface="epms-table-index"] tr .actions .disabled {
  pointer-events: all;
}
table[data-interface="epms-table-index"] tr.table-hover-off:hover td {
  background: initial;
}
/**
 * Login related LESS rules
 */
/**
 * Captcha box related
 */
.g-recaptcha > div {
  margin: 0 auto;
}
@media print {
  body > nav {
    display: none;
  }
  #elmo-navigation-title {
    padding-top: 1em;
  }
  ul.nav-tabs {
    display: none;
  }
}

/**
 * Bootstrap < 3.2.0 fix for missing visible-x-inline classes.
 * If we upgrade to 3.2.x or greater, remove this fix.
 */
.visible-xs-inline,
.visible-sm-inline,
.visible-md-inline,
.visible-lg-inline {
  display: none !important;
}
.visible-xs-inline-block,
.visible-sm-inline-block,
.visible-md-inline-block,
.visible-lg-inline-block {
  display: none !important;
}
@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important;
  }
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important;
  }
  .visible-md-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important;
  }
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}

/**

# ELMO Multi-tier dropdown

Allows a bootstrap drop-down to have sub-dropdown items.
http://bootsnipp.com/snippets/featured/multi-level-dropdown-menu-bs3

# Usage
```html
    <div class="dropdown">
        <ul class="dropdown-menu multi-level" role="menu" aria-labelledby="dropdownMenu">
            <li><a href="#">Some action</a></li>
            <li><a href="#">Some other action</a></li>
            <li class="dropdown-submenu">
                <a tabindex="-1" href="#">Hover me for more options</a>
                <ul class="dropdown-menu">
                    <li><a tabindex="-1" href="#">Second level</a></li>
                    <li class="dropdown-submenu">
                        <a href="#">Even More..</a>
                        <ul class="dropdown-menu">
                            <li><a href="#">3rd level</a></li>
                            <li><a href="#">3rd level</a></li>
                        </ul>
                    </li>
                    <li><a href="#">Second level</a></li>
                    <li><a href="#">Second level</a></li>
                </ul>
            </li>
            <li><a href="#">First level</a></li>
        </ul>
    </div>
```
*/
.dropdown-menu li {
  text-align: left;
}
.dropdown-menu li.dropdown-submenu {
  position: relative;
}
.dropdown-menu li.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
}
.dropdown-menu li.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.dropdown-menu li.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-right: -10px;
}
.dropdown-menu li.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}
.dropdown-menu li.dropdown-submenu.pull-left {
  float: none;
}
.dropdown-menu li.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

/**
 * Table styles for TMS
 * Relocated from default.css
 */
#elmo-table,
.elmo-table {
  border: none;
}
#elmo-table th,
.elmo-table th {
  border: none;
}
#elmo-table tbody tr:first-child td,
.elmo-table tbody tr:first-child td {
  border-top: none;
}
#elmo-table tfoot tr td,
.elmo-table tfoot tr td {
  border: none;
}
#elmo-table td,
.elmo-table td,
#elmo-table th,
.elmo-table th {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #ddd;
  border-left: none;
  border-right: none;
}
#elmo-table td.no-top-border,
.elmo-table td.no-top-border,
#elmo-table th.no-top-border,
.elmo-table th.no-top-border {
  border-top: none;
}
#elmo-table td.name-column,
.elmo-table td.name-column,
#elmo-table th.name-column,
.elmo-table th.name-column {
  width: 30%;
  word-break: break-all;
}
#elmo-table td p,
.elmo-table td p {
  margin: 0 !important;
}
#elmo-table tr.empty td,
.elmo-table tr.empty td {
  padding: 10px;
  text-align: center;
}
#elmo-table tr.empty td .glyphicon-scale-xl,
.elmo-table tr.empty td .glyphicon-scale-xl {
  opacity: 0.2;
  padding: 10px 20px 20px 20px;
}
#elmo-table tr.deleted,
.elmo-table tr.deleted {
  background-color: rgba(204, 204, 204, 0.5);
}
#elmo-table tr.deleted td,
.elmo-table tr.deleted td {
  opacity: 0.5;
}
#elmo-table tr.deleted td.actions,
.elmo-table tr.deleted td.actions {
  opacity: 1;
}
#elmo-table tr.deleted:hover td,
.elmo-table tr.deleted:hover td {
  opacity: 1;
}
#elmo-table div.div-table-cell,
.elmo-table div.div-table-cell {
  display: table-cell;
  vertical-align: middle;
  padding: 3px;
}
#elmo-table div.div-table-cell.main-cell,
.elmo-table div.div-table-cell.main-cell {
  width: 100%;
}
.user-config-container .config-table td.active {
  background-color: #999999 !important;
}
.user-config-container .config-header-16 {
  font-size: 16px !important;
}
.user-config-container .config-select-table {
  float: left;
  padding-left: 0px;
  width: 150px;
  background-color: white;
}
.user-config-container .config-repeated {
  float: right;
  padding-left: 0px;
  padding-right: 0px;
}
.user-config-container .config-left {
  float: left;
}
.user-config-container .config-right {
  float: right;
  padding-right: 20% !important;
}
.user-config-container .config-disabled {
  background-color: #DDD;
}
.user-config-container .config-selected {
  color: #888;
}
.user-config-container .config-label {
  float: left;
  padding-right: 0px;
}
.table-scrollable {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

/**
 * Glyphicon helpers
 */
.glyphicon.glyphicon-scale-sm {
  font-size: 0.8em;
}
.glyphicon.glyphicon-scale-lg {
  font-size: 5em;
}
.glyphicon.glyphicon-scale-xl {
  font-size: 10em;
}

/**
 * Input: Textarea
 */
textarea {
  /**
     * Resize Limit
     * Default behaviour makes Textareas only resize vertically
     */
  resize: vertical;
  min-height: min-content;
  /**
     * Resize / Horizontal
     * Allows text area size changes in only horizontal dimension
     */
  /**
     * Resize / Both
     * Allow text area size changes in both dimensions
     */
  /**
     * Resize / Horizontal
     * Allows text area size changes in only horizontal dimension
     */
}
textarea[data-resize="none"] {
  resize: none;
  min-height: unset;
  min-width: unset;
}
textarea[data-resize="both"] {
  resize: both;
  min-height: min-content;
  min-width: min-content;
}
textarea[data-resize="horizontal"] {
  resize: horizontal;
  min-width: min-content;
}

/**
 * # ELMO Pagination Small
 * Blantant rip-off of Atlassian Jira pagination styles
 *
 */
/**
 * Bootstrap Pagination extension
 *
 * ## HTML
 * Extends the default Bootstrap pagination class @see http://getbootstrap.com/components/#pagination
 * <ul class="pagination elmo-pagination-small"> .. </ul>
 */
.pagination.elmo-pagination-small {
  margin: 5px;
  border-radius: 0;
}
.pagination.elmo-pagination-small > li > a,
.pagination.elmo-pagination-small > li > span {
  border: none;
  padding: 4px;
}
.pagination.elmo-pagination-small > li > a:hover,
.pagination.elmo-pagination-small > li > span:hover,
.pagination.elmo-pagination-small > li > a:focus,
.pagination.elmo-pagination-small > li > span:focus {
  background-color: transparent;
  text-decoration: underline;
}
.pagination.elmo-pagination-small > .active > a,
.pagination.elmo-pagination-small > .active > span,
.pagination.elmo-pagination-small > .active > a:hover,
.pagination.elmo-pagination-small > .active > span:hover,
.pagination.elmo-pagination-small > .active > a:focus,
.pagination.elmo-pagination-small > .active > span:focus {
  font-weight: bold;
  color: #000;
  background-color: transparent;
}
.pagination.elmo-pagination-small > .next > a > span,
.pagination.elmo-pagination-small > .prev > a > span {
  width: 0;
  height: 0;
  display: block;
  overflow: hidden;
  margin-top: 0.2em;
  border-top: 0.5em solid transparent;
  border-bottom: 0.5em solid transparent;
}
.pagination.elmo-pagination-small > .next > a span {
  border-left: 0.5em solid #666;
}
.pagination.elmo-pagination-small > .next > a:hover span,
.pagination.elmo-pagination-small > .next > a:focus span {
  border-left-color: #000;
}
.pagination.elmo-pagination-small > .prev > a span {
  border-right: 0.5em solid #666;
}
.pagination.elmo-pagination-small > .prev > a:hover span,
.pagination.elmo-pagination-small > .prev > a:focus span {
  border-right-color: #000;
}
/**
 * Pagination counter styles
 *
 * ## HTML (custom)
 * <div class="pagination-count elmo-pagination-small"><span>1-10 of <a href="#">50</a></span></div>
 */
.pagination-count.elmo-pagination-small {
  margin: 5px;
  padding: 4px;
}

@media print {
  body > nav {
    display: none;
  }
  #elmo-navigation-title {
    padding-top: 1em;
  }
  ul.nav-tabs {
    display: none;
  }
}

/**
 * Fix for select2 required tooltip:
 * https://github.com/select2/select2/issues/128
 */
.form-control.userForm-country-control.select2-offscreen {
  top: 48px !important;
  width: 100% !important;
}
.form-control.userForm-state-control.select2-offscreen {
  top: 62px !important;
  width: 100% !important;
}

/**
 * SuperInfo styles
 * @see app/Resources/views/Elements/superInfo.html.twig
 */
span[data-trigger="sinfo"] {
  opacity: 1;
  color: #000;
  cursor: pointer;
  pointer-events: all;
}
.popover.super-info {
  z-index: 2000;
  min-height: 4em;
  text-align: center;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex !important;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
  min-width: 100px;
  max-width: none;
}
.popover.super-info .super-info-extended {
  margin: 0px -14px -9px -14px;
  padding: 5px 0;
  text-align: center;
}
.modal.modal-sinfo {
  z-index: 2500;
}

/**
 * Login related LESS rules
 */
/**
 * Captcha box related
 */
.g-recaptcha > div {
  margin: 0 auto;
}

/**
 * Styles for the epms-table-index template
 * @see app/Resources/views/Page/Admin/table_view.html.twig
 */
table[data-interface="epms-table-index"] tr[data-url] {
  cursor: pointer;
}
table[data-interface="epms-table-index"] tr .actions {
  white-space: nowrap;
}
table[data-interface="epms-table-index"] tr .actions .disabled {
  pointer-events: all;
}
table[data-interface="epms-table-index"] tr.table-hover-off:hover td {
  background: initial;
}
