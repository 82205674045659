// Block UI without showing interception
.elmo-busy-invisible {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2147483647;
    cursor: progress;
}

// Block UI styles
.elmo-busy {

    // main Block style
    width: 100%;
    height: 100%;
    cursor: progress;
    overflow: hidden;

    .wrapper {

        // Background appearance
        background: rgba(255,255,255,0.50);
        text-align: center;
        z-index:99;
        
        .align {

            // Vertical alignment for the image
            position: relative;
            
            // Safari: Apply width, so auto-margin centering works
            width: 100%;
            height: 100%;
        }

    }
}

/**
 * Note: Spinner can be used outside of elmo-busy, so it's important this is declared globally.
 */
.elmo-busy-spinner {

    // Size of the spinner
    width: 40px;
    height: 40px;

    // Image to use (note: .gif is IE fallback)
    background: url('/assets/images/elmo-busy/loading.gif');
    background: url('/assets/images/elmo-busy/loading.svg'), linear-gradient(transparent, transparent);

    // Center position
    position: absolute;
    top: 50%;
    left: 50%;

    // Fix alignment spacing for proper centering
    margin-top: -20px;
    margin-left: -20px;
}