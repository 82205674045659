/**
 * Styles for the epms-table-index template
 * @see app/Resources/views/Page/Admin/table_view.html.twig
 */
table[data-interface="epms-table-index"] {

    tr {

        // TR with Data URL supplied
        &[data-url] {
            cursor: pointer;
        }

        .actions {
            // All controls should be on a single line unless manually broken with a <BR>.
            white-space: nowrap;

            // Bugfix: Make disabled table controls still grab the cursor events
            // https://github.com/twbs/bootstrap/issues/17703
            .disabled {
                pointer-events: all;
            }
        }

        // Suppress the hover effect for the specific row
        &.table-hover-off {
            &:hover {
                td {
                    background: initial;
                }
            }
        }
    }

}