/**
 * # ELMO Pagination Small
 * Blantant rip-off of Atlassian Jira pagination styles
 *
 */

@pagination-hover-bg: transparent;
@pagination-bg: transparent;
@pagination-active-bg: transparent;
@pagination-active-color: #000;
@pagination-arrow: #666;
@pagination-arrow-hover: #000;
@pagination-padding: 4px;
@pagination-margin: 5px;

/**
 * Bootstrap Pagination extension
 *
 * ## HTML
 * Extends the default Bootstrap pagination class @see http://getbootstrap.com/components/#pagination
 * <ul class="pagination elmo-pagination-small"> .. </ul>
 */
.pagination.elmo-pagination-small {

    margin: @pagination-margin;
    border-radius: 0;

    // Page buttons
    > li > a,
    > li > span {

        border: none;
        padding: @pagination-padding;

        &:hover,
        &:focus {
            background-color: @pagination-hover-bg;
            text-decoration: underline;
        }
    }

    // Active selection
    > .active > a,
    > .active > span {
        &,
        &:hover,
        &:focus {
            font-weight: bold;
            color: @pagination-active-color;
            background-color: @pagination-active-bg;
        }
    }

    // Next and previous page arrow buttons.
    // THESE ARE NEW! Use the right HTML:
    //
    //  <li class="next"><a href="#"><span>Next Page</span></a></li>
    > .next,
    > .prev {

        // Standard appearance
        > a > span {
            width: 0;
            height: 0;
            display: block;
            overflow: hidden;
            margin-top: 0.2em;
            border-top: 0.5em solid transparent;
            border-bottom: 0.5em solid transparent;
        }
    }
    > .next {
        > a {
            span {
                border-left: 0.5em solid @pagination-arrow;
            }

            &:hover,
            &:focus {
                span {
                    border-left-color: @pagination-arrow-hover;
                }
            }
        }
    }
    > .prev {
        > a {
            span {
                border-right: 0.5em solid @pagination-arrow;
            }

            &:hover,
            &:focus {
                span {
                    border-right-color: @pagination-arrow-hover;
                }
            }
        }
    }
}

/**
 * Pagination counter styles
 *
 * ## HTML (custom)
 * <div class="pagination-count elmo-pagination-small"><span>1-10 of <a href="#">50</a></span></div>
 */
.pagination-count.elmo-pagination-small {
    margin: @pagination-margin;
    padding: @pagination-padding;
}
