@media print {

  // Hide the top-bar nav
  body>nav {
    display:none;
  }

  // Reduce padding for top title
  #elmo-navigation-title {
    padding-top: 1em;
  }

  // Hide navigation tabs
  ul.nav-tabs {
    display: none;
  }
}
