/**

# ELMO Multi-tier dropdown

Allows a bootstrap drop-down to have sub-dropdown items.
http://bootsnipp.com/snippets/featured/multi-level-dropdown-menu-bs3

# Usage
```html
    <div class="dropdown">
        <ul class="dropdown-menu multi-level" role="menu" aria-labelledby="dropdownMenu">
            <li><a href="#">Some action</a></li>
            <li><a href="#">Some other action</a></li>
            <li class="dropdown-submenu">
                <a tabindex="-1" href="#">Hover me for more options</a>
                <ul class="dropdown-menu">
                    <li><a tabindex="-1" href="#">Second level</a></li>
                    <li class="dropdown-submenu">
                        <a href="#">Even More..</a>
                        <ul class="dropdown-menu">
                            <li><a href="#">3rd level</a></li>
                            <li><a href="#">3rd level</a></li>
                        </ul>
                    </li>
                    <li><a href="#">Second level</a></li>
                    <li><a href="#">Second level</a></li>
                </ul>
            </li>
            <li><a href="#">First level</a></li>
        </ul>
    </div>
```
*/

.dropdown-menu li {

    text-align: left;

    &.dropdown-submenu {
        position: relative;

        & > .dropdown-menu {
            top: 0;
            left: 100%;
            margin-top: -6px;
            margin-left: -1px;
            -webkit-border-radius: 0 6px 6px 6px;
            -moz-border-radius: 0 6px 6px;
            border-radius: 0 6px 6px 6px;
        }

        &:hover > .dropdown-menu {
            display: block;
        }

        & > a:after {
            display: block;
            content: " ";
            float: right;
            width: 0;
            height: 0;
            border-color: transparent;
            border-style: solid;
            border-width: 5px 0 5px 5px;
            border-left-color: #ccc;
            margin-top: 5px;
            margin-right: -10px;
        }

        &:hover > a:after {
            border-left-color: #fff;
        }

        &.pull-left {
            float: none;
        }

        &.pull-left > .dropdown-menu {
            left: -100%;
            margin-left: 10px;
            -webkit-border-radius: 6px 0 6px 6px;
            -moz-border-radius: 6px 0 6px 6px;
            border-radius: 6px 0 6px 6px;
        }
    }
}
