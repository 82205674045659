@import "src/Elmo/AppBundle/Resources/css/framework/status.less";

/**
 * Document Statuses
 *
 * Example
 * <span class="elmo-document-state state-not-yet-started">Not Yet Started</span>
 */
.elmo-document-state {

    // Not Yet Started
    &.state-not-yet-started {
        color: @elmo-status-colors-red;
    }

    // Recompletion Required
    &.state-recompletion {
        color: @elmo-status-colors-red;
    }

    // Expired
    &.state-expired {
        color: @elmo-status-colors-red;
    }

    // Pending
    &.state-pending-approval {
        color: @elmo-status-colors-orange;
    }

    // Rejected
    &.state-rejected {
        color: @elmo-status-colors-red;
    }

    // Completed
    &.state-completed {
        color: @elmo-status-colors-green;
    }

    // In Progress
    &.state-in-progress {
        color: @elmo-status-colors-orange;
    }

}
