/**
 * Table styles for TMS
 * Relocated from default.css
 */

#elmo-table,
.elmo-table {
    border: none;

    th {
        border: none;
    }

    tbody tr:first-child td {
        border-top: none;
    }

    tfoot tr td {
        border: none;
    }

    td,
    th {
        border-top: 1px solid #fff;
        &.no-top-border {
            border-top: none;
        }
        border-bottom: 1px solid #ddd;

        // This is only required for instances of #elmo-table using .table-bordered class.
        border-left: none;
        border-right: none;

        &.name-column {
            width:30%;
            word-break: break-all;
        }
    }

    td p {
        margin: 0 !important;
    }

    // Styles for the "empty table" row
    tr.empty td {
        padding: 10px;
        text-align: center;

        // Supermassive icon
        .glyphicon-scale-xl {
            opacity: 0.2;
            padding: 10px 20px 20px 20px;
        }
    }

    // Deleted row styles
    tr.deleted {
        background-color: rgba(204,204,204, 0.5);

        // Cell content has low opacity.
        td {
            opacity: 0.5;

            // Actions retains full opacity
            &.actions {
                opacity: 1;
            }
        }

        // Hovering removes the opacity penalty.
        &:hover td {
            opacity: 1;
        }
    }

    div.div-table-cell{
        display:table-cell;
        vertical-align: middle;
        padding:3px;
        &.main-cell{
            width:100%;
        }
    }
}
.user-config-container {
    .config-table {
        td.active {
            background-color: #999999 !important;
        }
    }
    .config-header-16 {
        font-size: 16px !important;
    }
    .config-select-table {
        float: left;
        padding-left: 0px;
        width: 150px;
        background-color: white;
    }
    .config-repeated {
        float: right;
        padding-left: 0px;
        padding-right: 0px;
    }
    .config-left {
        float: left;
    }
    .config-right {
        float: right;
        padding-right: 20% !important;
    }
    .config-disabled {
        background-color: #DDD;
    }
    .config-selected {
        color: #888;
    }
    .config-label {
        float: left;
        padding-right: 0px;
    }
}
.table-scrollable {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}
