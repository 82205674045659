/**
 * Input: Textarea
 */

textarea {

    /**
     * Resize Limit
     * Default behaviour makes Textareas only resize vertically
     */
    resize: vertical;
    min-height: min-content; // Prevent the box being made smaller than rows spec

    /**
     * Resize / Horizontal
     * Allows text area size changes in only horizontal dimension
     */
    &[data-resize="none"] {
        resize: none;
        min-height: unset;
        min-width: unset;
    }

    /**
     * Resize / Both
     * Allow text area size changes in both dimensions
     */
    &[data-resize="both"] {
        resize: both;

        // Prevent the box being made smaller than rows/cols spec
        min-height: min-content;
        min-width: min-content;
    }

    /**
     * Resize / Horizontal
     * Allows text area size changes in only horizontal dimension
     */
    &[data-resize="horizontal"] {
        resize: horizontal;

        // Prevent the box being made smaller than cols spec
        min-width: min-content;
    }

}