/* General */
a[data-id='changeHistory'] > span.badge, .badge-effective {
    background-color: rgb(214, 228, 255);
    color: rgb(33, 33, 33);
    font-weight: 500;
    padding: 0.3rem 0.75rem;
}

/* Effectivity Form */
.effectiveDateForm,
#effectivityForm,
#basicEffectivityForm,
#position-assignment-duration-form-top {
    background-color: #F6F6F6;
    border-radius: 8px;
    padding: 14px 0;
    margin: 20px 0 0 0;
}

.effectiveDateForm h4,
#effectivityForm h4,
#basicEffectivityForm h4{
    font-weight: 700;
    line-height: 21.98px;
}

#effectivityFormConfirmation #effectivityFormConfirmationEffectivityFields {
    margin-top: 14px;
}

#effectivityFormConfirmation .modal-footer {
    border-top: 0;
}

#effectivityForm .form-control.select2-offscreen,
#position-assignment-duration-form-top .form-control.select2-offscreen,
.make-primary-position-confirmation-wrapper .form-control.select2-offscreen {
    left: 22px !important;
    top: 58px !important;
}

#effectivityForm .effectiveDating-autofill-label {
    vertical-align: middle;
    font-weight: 400
}

.effectivity-notice-section {
    margin-top: 24px;
    margin-bottom: 4px;
}

@media (max-width:767px) {
    .effectiveDating-autofill-wrapper > input[type=checkbox].effectiveDating-autofill {
        width: 13px;
        height: 13px;
        margin-right: 0;
        margin-left: 13px
    }
}
