/**
 * Login related LESS rules
 */

/**
 * Captcha box related
 */
.g-recaptcha {

    // Center the captcha widget, as it has a fixed width
    &> div {
        margin: 0 auto;
    }
}