/**
 * SuperInfo styles
 * @see app/Resources/views/Elements/superInfo.html.twig
 */

span[data-trigger="sinfo"] {

    // SuperInfo does not obey suppression rules
    opacity: 1;
    color: #000;
    cursor: pointer;
    pointer-events: all;
}

.popover {
    &.super-info {

        // 2000 is higher than any Modal dialog or Background.
        // This lets Popovers show over modals when using the Body container
        // It's important to ensure the SuperInfo popover is closed when the SuperInfo modal is opened.
        z-index: 2000;

        // Minimum height due to how popover works
        min-height: 4em;

        // Override the center alignment with your own span/div container.
        text-align: center;

        // Flexbox centered alignment. Requires specific display
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex !important;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-box-align: center;
        align-items: center;

        // Popover footer "Extended Info" button container
        .super-info-extended {
            margin: 0px -14px -9px -14px;
            padding: 5px 0;
            text-align: center;
        }

        // Remove width constraints and let popovers be fluid
        min-width: 100px;
        max-width: none;
    }
}

.modal {

    // SuperInfo models have higher Z-indexes then SuperInfo popovers.
    // You can't use a popover in a modal!
    &.modal-sinfo {
        z-index: 2500;
    }

}